/* .left-contact {
    text-align: center;
    width: 48%;
}
.right-contact {
    text-align: end;
    width: 48%;
}
.right-contact-img{
    
    width: 80%;
}
.botn {
    justify-content: center;
}
@media(max-width:768px){
    .left-contact,.right-contact{
        text-align: center;
        width: 100%;
       
    }
    .right-contact-img {
        width: 100%;
        
    }
    .left-contact {
        text-align: start;
       
    }

    .botn {
        justify-content: start;
    }
} */

.contact-container {
    display: flex;
    width: 100%;
    justify-content: center;
    column-gap: 20px;
    row-gap: 20px;
}

.contact-form {
    width: 40%;
   
}

.map {
    width: 40%;
}

@media(max-width:768px) {

    .contact-form,
    .map,
    .button {
        width: 100%;
    }
}
.left-about{
    width: 48%;
}
.right-about {
    width: 48%;
}
.about-img {
    width: 70%;
}
.about-heading {
   background-color: #9696fc; 
}
.skill-container-img {
    width: 80px;
}
.skill-img{
    width: 60px;
}

@media (max-width: 768px){
    .top-container{
        flex-direction: column;
        text-align: center;

    }
    .left-about, .right-about {
        width: 100%;
    }
    .about-img{
        width: 100%;
    }
}
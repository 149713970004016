.heading-3 {
    font-size: 2rem;
    font-weight: 700;
    color: #454545;
}

.para {
    color: #454545;
    line-height: 1.5;
    font-size: 1.1rem;
}

.hero-image-parent {
    width: 50%;
    box-shadow: 0px 4px 6px purple;
    height: fit-content;
    border-radius: 15px;
    
}

.input-range {
    top: 50%;
    left: 0%;
    width: 100%;
}
.upload{
    /* border: 2px dashed #800080; */
    padding: 1rem;
}
.slider {
    -webkit-appearance: none;
    background:transparent;
}
.slider::-webkit-slider-thumb{
    appearance: none;
    width: 30px;
    min-height: 30px;
    background-image: url("/public/images/icon.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
}
.foreground-img {
    background-image: url("/public/images/transparent.jpg");
}
.custom-mt{
    margin-top: 6rem;
}

@media (max-width: 768px){
   .my-container{
    flex-direction: column-reverse;
   }
   .hero-section {
    width: 90%;
    margin: auto;
   }
   .hero-image-parent {
    width: 90%;
    margin-left: 1rem;
    margin-right: 1rem;
    border-radius: 25px;
   }
   .foreground-img, .my-img{
    border-radius: 25px;
   }
}
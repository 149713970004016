.heading-3 {
    font-size: 2rem;
    font-weight: 700;
    color: #454545;
}

.para {
    color: #454545;
    line-height: 1.5;
    font-size: 1.1rem;
}

.feature-collage-container {
    margin-top: 13rem;

}
.left-collage {
    width: 48%;
    margin-left: 6rem;
    margin-top: 6rem;
}
.right-collage {
    width: 48%;
}
.collage-container {
    width: 50%;
}
.collage-img {
    width: 100%;
    border: 1px solid gray;
    border-radius: 15px;
}

@media (max-width: 768px){
   
    .collage-img {
       border-radius: 25px;
    }
    .left-collage {
        width: 90%;
        margin:auto;
        text-align: center;
        
    }
    .right-collage {
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: center;
    }
}
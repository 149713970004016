.heading-3{
  font-size: 32px;
  font-weight: 700;
  color: #454545;
}

.heading-4 {
  font-size: 22px;
  font-weight: 600;
  color: #454545;

}
.para {
  color: #454545;
  line-height: 1.5;
  font-size: 18px;
}

.accordion {
  border-bottom: 1px solid #ddd;
  /* border-radius: 5px; */
  overflow: hidden;
  width: 90%;
  margin: 20px auto;
}

.accordion-item {
  /* border-bottom: 1px solid #ddd; */
}

.accordion-header {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  cursor: pointer;
  background: white;
}

/* .accordion-header:hover {
    background: #f0f0f0;
  } */

.accordion-content {
  padding: 15px;
  background: #fff;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media(max-width: 768px){
  .accordian{
    width: 90%;
    margin: auto;
  }
}
.removed-back-img {
    width: 90%;
   margin: auto;
   gap: 3rem;
    
}
.downloaded-img {
    box-shadow: 0px 4px 6px rgb(173, 171, 171);
    border-radius: 20px;
    background-color: white;
    width: 50vw;
    max-height: 500px;
    background-size: cover;
    background-image: url("/public/images/transparent.jpg");
}

/* processed-images.css */

/* Base button styles */
.btn {
    display: flex ;
    flex-direction: column;
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* PNG button (purple gradient) */
.btn-png {
   
    border-radius: 11px;
    padding: 15px 30px;
    border: none;
    font-size: 20px;
    background: linear-gradient(90deg, #03479b, #1e7cee);
    color: white;
    box-shadow: 0 4px 8px rgba(128, 0, 128, 0.3);
}

.btn-png:hover {
    background: linear-gradient(90deg, #1e7cee, #1e7cee);
    /* transform: translateY(-3px); */
    box-shadow: 0 6px 12px rgba(128, 0, 128, 0.5);
}

/* JPG button (green gradient) */
.btn-jpg {
    
    border-radius: 11px;
    padding: 15px 30px;
    border: none;
    font-size: 20px;
    background: linear-gradient(90deg, #2ecc71, #27ae60);
    color: white;
    box-shadow: 0 4px 8px rgba(0, 128, 0, 0.3);
    margin-left: 10px;
}

.btn-jpg:hover {
    background: linear-gradient(90deg, #27ae60, #2ecc71);
    /* transform: translateY(-3px); */
    box-shadow: 0 6px 12px rgba(0, 128, 0, 0.5);
}

/* Add focus effect for accessibility */
.btn:focus {
    outline: 3px solid rgba(128, 0, 128, 0.5);
}

@media (max-width: 500px){
    .btn-jpg, .btn-png {
        width: 80%;
    }
    .downloaded-img {
        width: 80%;
    }
    .removed-back-img {
        flex-direction: column;
    }
}
.upload-button {



    /* margin-right: 20%; */
}

label {

   
}

.removed-back-img {
    display: inline;
   
}

.column {
    display: flex;
    flex-direction: column;
    text-align: center;
}
/* .file-upload {
   background-color: purple;
} */
.btn-upload{
    border-radius: 11px;
    padding: 15px 30px;
    border: none;
    font-size: 15px;
    background: linear-gradient(90deg, #0f70e6, #025dcc);
    color: white;
    box-shadow: 0 4px 8px rgba(90, 79, 247, 0.3);
    font-size: 20px;
}
.btn-upload:hover {
    cursor: pointer;
}

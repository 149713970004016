.iconss{
    background-color: #0288b3;
    color: white;
   
}
@media(max-width:768px){
    .iconss{
        flex-direction: column;
       
       
    }
    .ic, .iconss{
        justify-content: center;
        text-align: center;
    }
    
}
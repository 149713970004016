.heading-2{
    font-size: 3rem;
    font-weight: 700;
    color: #454545;
}

.heading-3 {
    font-size: 2rem;
    font-weight: 700;
    color: #454545;
}

.para {
    color: #454545;
    line-height: 1.5;
    font-size: 1.1rem;
}
.left-side {
    width: 48%;
}

.left-img-container {
    width: 100%;
    display: flex;
    justify-content: center;
   
   
}

.left-img {
    width: 75%;
    border-radius: 15px;
   

}

.right-side {
    width: 48%;
    margin-top: 3%;
    
}

.foreground-img {
    background-image: url("/public/images/transparent.jpg");
}

.input-range-feature {
    top: 100px;
    left: 0;
    width: 75%;
}

.slider-feature {
    -webkit-appearance: none;
    background: transparent;
}
.slider-feature::-webkit-slider-thumb{
    appearance: none;
    width: 30px;
    min-height: 30px;
    background-image: url("/public/images/icon.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
}

@media (max-width: 768px) {
    .left-side {
        width: 100%;
    }

    .right-side {
        width: 100%;
        justify-content: center;
        text-align: center;
        bottom: -200px;
    }

    .left-img-container {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 10px;
       

    }

    .left-img {
        width: 90%;
    }

    .input-range-feature {
        top: 100px;
        left: 17px;
        width: 90%;

    }
}

@media (max-width: 768px) {
   .feature-section {
    width: 90%;
    margin: auto;
   }

   
    .left-img {
       border-radius: 25px;
    }
    .responsive-br{
        display: none;
    }
}
.my-container{
    gap: 3rem;
}
.heading-3 {
    font-size: 2rem;
    font-weight: 700;
    color: #454545;
}

.para {
    color: #454545;
    line-height: 1.5;
    font-size: 1.1rem;
}

.image-parent-bg {
    width: 50%;
    box-shadow: 0px 4px 6px rgb(117, 117, 241);
    height: fit-content;
    border-radius: 15px;

}

.input-range {
    top: 50%;
    left: 0%;
    width: 100%;
}

.upload {
    /* border: 2px dashed #800080; */
    padding: 1rem;
}

.slider {
    -webkit-appearance: none;
    /* background:transparent; */
    background-color: #f2f3f7;
}

.slider::-webkit-slider-thumb {
    appearance: none;
    width: 30px;
    min-height: 30px;
    background-image: url("/public/images/icon.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
}

.foreground-img-bg {
    /* background-image: url("/public/images/transparent.jpg"); */
    /* background-color: #eef0f7; */
    background-color: white;
}

@media (max-width: 768px) {
    .my-container {
        flex-direction: column-reverse;
        width: 90%;
        margin-left: 1rem;
        margin-right: 1rem;
        gap: 0.5rem;


    }

    .image-parent-bg {
        width: 90%;
        align-items: center;
        text-align: center;
        justify-content: center;
        align-content: center;
        border-radius: 25px;
        margin: auto;

    }
   
}
/* Header Styling */
.top-header {
    background: linear-gradient(90deg, #1e7cee,  #025dcc);
    color: white;
    position: relative;
    z-index: 10;
  }
  
  .menu-container {
    flex-direction: column; /* Stack for mobile */
  }
  
  .menu {
    display: flex;
    flex-direction: column; /* Stack items vertically for mobile */
    gap: 15px;
  }
  
  .menu-open {
    display: flex !important;
    background: linear-gradient(90deg, #1e7cee,  #025dcc);
    padding: 15px;
    border-radius: 5px;
    position: absolute;
    top: 60px; /* Adjust for header height */
    right: 0;
    width: 100%; /* Full width for mobile */
  }
  
  .menu span {
    padding: 5px 10px;
    cursor: pointer;
  }
  
  /* Responsive "Get Started" Button */
  .get-started button {
    font-size: 16px;
  }
  
  @media (min-width: 768px) {
    .menu-container {
      flex-direction: row;
      align-items: center;
    }
  
    .menu {
      flex-direction: row; /* Horizontal layout for larger screens */
    }
  
    .menu-open {
      position: static;
      display: flex;
    }
  
    .get-started button {
      margin-top: 0;
    }
  }
  